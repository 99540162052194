import * as React from "react"

const Release = ({pageContext}) => {
  const { release } = pageContext;
  const { releaseData } = release;
  const songs = release.songs.nodes;
  return (
    <div key={release.id}>
      <h1>{release.name}</h1>
      <p>{releaseData.artist} | {releaseData.date}</p>
      <img style={{maxWidth: "500px"}} src={releaseData.art.sourceUrl} alt={release.name} />
      <h2>Tracks</h2>
      <ol>
      {songs.map( song => {
        return(
          <li key={song.id}>{song.title}</li>
        )
      })}
      </ol>
    </div>
  )
}

export default Release;
